/* Hint Modal Styles */

/* ======== General ======== */

.hint-modal {
    z-index: 9;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.85);
    position: absolute;
    padding-top: 30vh;
}

.hint-modal h1 {
    margin-top: 0px !important;
    color: white !important;
    font-weight: bold;
    padding-right: 10vw;
    padding-left: 10vw;
}

.hint-modal img {
    width: 50% !important;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.hidden {
    display: none !important;
}

/*@media screen and (min-width: 600px) {
    .hint-modal {
        display: none !important;
    }
}*/