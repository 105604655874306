/* Custom CSS */
.progress {
    border-radius:0!important;
    position:fixed!important;
    bottom:58px;
    left:0!important;
    right:0!important;
}

.animated-progress {
    /*animation: animate-bar 1s ease-out forwards;*/
}

.animated-progress .progress-bar {
    background-size: 30px 30px;
    background-image: linear-gradient(135deg, rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%,
    transparent 75%, transparent);
    animation: animate-stripes 1s linear;
}

/*@keyframes animate-bar {
    0% {
        transform: scale(1.1)
    }
    100% {
        transform: scale(1.0)
    }
}*/

.progress-bar {
    background-color: #45BAB4 !important;
    transition: width 1s ease-in-out;
}

/*.highlighted-bar {
    box-shadow:0 0 10px #9ecaed;
    clip-path: inset(0px -15px 0px 0px);
}*/

.highlighted-bar {
    background-size: 30px 30px;
    background-image: linear-gradient(135deg, rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%,
    transparent 75%, transparent);
    animation: animate-stripes 1s linear;

}

@keyframes animate-stripes {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 60px 0;
    }
}