/* Cookie Banner Style */

/* ======== Responsive ======== */

@media (max-width: 450px) {
    .react-cookie-law-dialog {
        height:100%;
    }
}

/* ======== Controls ======== */

.react-cookie-law-container {

}

.react-cookie-law-message {

}

.react-cookie-law-policy {

}

.react-cookie-law-select-pane {

}

.react-cookie-law-option-wrapper {

}

.react-cookie-law-option-wrapper label {
    color:black!important;
}

.react-cookie-law-option-checkbox {
}

.react-cookie-law-dialog {

}

.react-cookie-law-dialog {

}

.react-cookie-law-accept-btn {
    background-color: grey!important;
    margin-right:10px!important;
}

.react-cookie-law-accept-all-btn {
    background-color: black!important;
    margin-top:10px!important;
}

.react-cookie-law-decline-btn {

}