/* Multistep Modal Style */

/* ======== Step Indicator ======== */

.step {
    /*
    height: 15px;
    width: 15px;*/
    height: 5px;
    width: 5px;
    margin: auto 3px;
    background-color: #62a594;
    border: none;
    border-radius: 50%;
    display: inline-block;
    opacity: 0.5;
    vertical-align: middle;
}

.step.active {
    opacity: 1;
    height: 7px;
    width: 7px;
}

.steps {
    text-align: center;
    line-height: 3em;
    /*margin-top: 40px;*/
}

/* TODO: set to 330 again if old nav-style is chosen */
@media (max-width: 300px) {
    .steps {
        display:none!important;
    }
}
/*
@media (max-height: 688px) {
    .steps {
        display:none!important;
    }
}
*/

/* ======== Modal itself ======== */

.bottom {
    position: absolute;
    bottom: 5px;
    width: calc(100% - 2rem);
}

.modal-nav {
    width: 100%;
    padding-top: 1rem;
    margin-bottom: 1em;
    margin-right: auto;
    margin-left: auto;
}

.question-next {
    margin: auto;
    margin-bottom: .3rem;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 25px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius: 180px;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #2D7161;
    cursor: pointer;
    border-radius: 180px;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #2D7161;
    cursor: pointer;
    border-radius: 180px;
}

.question-title {
    padding-bottom: .5rem;
    /*font-weight: 400;
    font-size:1.5rem;*/
    font-weight:600;
    font-size:1rem;
}

.header-logo {
    width:50%;
    margin-right:25%;
    margin-left:25%;
    margin-bottom:0.8rem;
}

.modal-header .close {
    position:absolute;
    top:20px;
    right: 20px;
}

/* ======== Responsive ======== */


/* ======== Tiles ======== */

.tile {
    background-color: white;
    border: 1px solid #62a594; /*!important;*/
    color: black;
    font-size: 1rem;
    cursor: pointer;
    margin: 0 auto;
    display:block;
    width: 5em;
    height: 5em;
    outline: none !important;
    border-radius: 50%;
    padding: .5em;
}

.tile > .fa-3x {
    text-align:center;
}

@media (max-width: 330px) {
    .tile {
        width: 3em;
        height: 3em;
    }

    .fa-3x:not(.next-icon):not(.back-icon) {
        font-size: 1.5em!important;
        height: 100%;
        width: 100%;
    }
}

.check-override {
    border-width: 3px;
    border-radius: 15px;
    -webkit-appearance: inherit;
    text-align: center;
    vertical-align: middle;
    /* padding: 22px; */
    position: relative;
    /* padding: calc(17%); */
    /* display: inline-block; */
    /* line-height: 100px; */
}

.tile:checked {
    background-color: #62a594;
}

.tile-unchecked {
    border: 1px solid darkgrey;
}

.tile:hover {
    border-color: #2D7161;
}

.tile-set {
    margin: 0 0 1.5em;
    width:150px;
    padding-left:5px;
    padding-right:5px;
}

.tile-description {
    display: block;
    opacity: 1;
    font-size: 1rem;
    text-align: center;
    cursor: pointer;
    margin-top: .5em;
}

.tile-description-unchecked {
    color: darkgrey;
}

.tile-active {
    background-color: #62a594 !important;
}

.tile-icon-active {
    color: white !important;
}

.tile-icon {
    color: #62a594;
    height: 100%;
}

.tile-icon-unchecked {
    color: darkgrey;
}

/*
.tile-text {
    margin-top: 10px;
    text-align: center;
    font-size: 1rem;
}
*/

.tile-container {
    margin: 0;
    width: 100%;
    display:flex;
    justify-content: center;
    position: relative;
}

.tile-restored {
    background-color: #62a594 !important;
}

/*
.tile-check-selected::before,
.tile-check-selected::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-color: transparent;
    border-style: solid;
}
*/
.tile-check-selected::after {
    font-family: "FontAwesome";
    content: "\f00c";
    font-size: 2.5em;
    /*line-height: 1.5rem;*/
    font-weight: bold;
    color: #fff;
}

.tile-check-selected::before {
    border-width: 20px;
    border-left-color: #2D7161;
    border-top-color: #2D7161;
    border-radius: 15px 0px 0px 0px;
}

@media (max-width: 330px) {
    .tile-check-selected::before {
        border-width: 18px;
    }

    .tile-check-selected::after {
        font-size: 1.5em;
        line-height: 1em;
    }
}

@media (max-width: 1024px) {
    .tile-description {
        opacity: 1 !important;
    }
}

.radiolbl {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.radiolbl input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}

.radiolbl:hover input ~ .checkmark {
    background-color: #ccc;
}

.radiolbl input:checked ~ .checkmark {
    background-color: #62a594;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.radiolbl input:checked ~ .checkmark:after {
    display: block;
}

.radiolbl .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.pulse-button {
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.06);
    }
    100% {
        transform: scale(1);
    }
}

/* Modal Nav */

.next-icon, .back-icon {
    cursor: pointer;
}

.arrow-btn {
    width: 3em;
    height: 3em;
    line-height: 2.7em;
    border-radius: 50%;
    text-align: center;
    margin:auto;
    cursor:pointer;
}

.arrow-btn.question-next {
    color: #fff;
    background-color: #62a594;
}

.arrow-btn.question-back {
    color: darkgrey;
    background-color: rgba(0,0,0,0.05);
}

.arrow-btn.question-next:hover {
    background-color: #2D7161;
}

.arrow-btn.question-back:hover {
    background-color: rgba(0,0,0,0.02);
}

.arrow-icon {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: inherit;
    font-size: 1.5em;
}