#specifity {
    padding-left: 1rem;
}

#specifity-title {
    text-align:center;
    margin:auto;
}

.specifity-bar {
    background: rgb(219,105,125);
    background: linear-gradient(90deg, rgba(219,105,125,1) 0%, rgba(255,219,162,1) 33%, rgba(169,227,144,1) 66%, rgba(128,200,97,1) 100%);
    border: 3px solid white;
    border-radius: 1rem;
    height: 20px;
    display: block;
    margin: 0 auto;
}

.specify-slider {
    -webkit-appearance: none;
    width: 100%;
    background: #62a594;
    opacity: 1;
}

input[type="range"].specify-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width:25px;
    height: 25px;
    background-size: contain;
    background-image: url("/src/assets/img/chevron-down.png");
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-origin: content-box;
    box-shadow:none;
    opacity: 1;
}

input[type="range"].specify-slider::-moz-range-thumb {
    border-radius: 50%;
    width:25px;
    height: 25px;
    background-size: contain;
    cursor: pointer;
    background-image: url("/src/assets/img/chevron-down.png");
    /*background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABAEAQAAABQ8GUWAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQflChMHFScVsYW7AAACDklEQVR42u2aQUrrUBRAX5FSsAVB6MRpoYuwE3ULuhCdOtRCZ+5FQZyIojhxICLdgxNFIYiC4j0OovngJz9N+/LeTf8989zcc5KWEOKcYRiGYRiGYRi/Qbpd6PWg3Y69S+ndabeh10O63SnEt7ZgPCbj/R0OD5F+P7ZY8e79PhwdwcfHn/3HY9jcnLDcaEQuz88wGMSWzN99MEh3zGM0KhgwHFKEvLzA2lps2b93X12FJCne/+AgZ8D+fuHBSiNMLJ8XAXZ3Jz44I0k0/BzS276EfMZw+D1gfR1Eyg+IH2F6eUidNzYcnJxMNyBuhNnkfzg+dv/+19QZwY88wNOTg7e32QeFi+BPHuD11cHdnZ9h1UfwKw/I7a2DnR1/AaqL4F0egO1thzSbyOmp5gjVyF9cIM3m9wkWF+HszOt8Tw9LpR9yJuLmBpaXf51IX4Rg8hojBJfXFCGavIYI0eVjRlAjHyOCOvmQEdTKh4igXj6LIJ0OXF76XTRJ/MtfXyNLS17lK70TvFLBla9PhADyeiMElNcXIYK8nggR5eNHUCAfL4Ii+fARFMqHi6BYvvoINZCvLkKN5P1HqKG8vwg1lp89whzITx9hjuTLR5hD+SyCdDrI+Xm+/NVVZS8ztIC0WunHWI+Pmbc8PMDeHtJqhd6nES0ECwuOlZV0i/v7RuPzM9YuhmEYhmEYhvEf8gVCKGePykG3KQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMS0xMC0xOVQwNzoyMTozOSswMDowMPXEan4AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjEtMTAtMTlUMDc6MjE6MzkrMDA6MDCEmdLCAAAAAElFTkSuQmCC");*/
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-origin: content-box;
}
/*
data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABAEAQAAABQ8GUWAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQflChMHFScVsYW7AAACDklEQVR42u2aQUrrUBRAX5FSsAVB6MRpoYuwE3ULuhCdOtRCZ+5FQZyIojhxICLdgxNFIYiC4j0OovngJz9N+/LeTf8989zcc5KWEOKcYRiGYRiGYRi/Qbpd6PWg3Y69S+ndabeh10O63SnEt7ZgPCbj/R0OD5F+P7ZY8e79PhwdwcfHn/3HY9jcnLDcaEQuz88wGMSWzN99MEh3zGM0KhgwHFKEvLzA2lps2b93X12FJCne/+AgZ8D+fuHBSiNMLJ8XAXZ3Jz44I0k0/BzS276EfMZw+D1gfR1Eyg+IH2F6eUidNzYcnJxMNyBuhNnkfzg+dv/+19QZwY88wNOTg7e32QeFi+BPHuD11cHdnZ9h1UfwKw/I7a2DnR1/AaqL4F0egO1thzSbyOmp5gjVyF9cIM3m9wkWF+HszOt8Tw9LpR9yJuLmBpaXf51IX4Rg8hojBJfXFCGavIYI0eVjRlAjHyOCOvmQEdTKh4igXj6LIJ0OXF76XTRJ/MtfXyNLS17lK70TvFLBla9PhADyeiMElNcXIYK8nggR5eNHUCAfL4Ii+fARFMqHi6BYvvoINZCvLkKN5P1HqKG8vwg1lp89whzITx9hjuTLR5hD+SyCdDrI+Xm+/NVVZS8ztIC0WunHWI+Pmbc8PMDeHtJqhd6nES0ECwuOlZV0i/v7RuPzM9YuhmEYhmEYhvEf8gVCKGePykG3KQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMS0xMC0xOVQwNzoyMTozOSswMDowMPXEan4AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjEtMTAtMTlUMDc6MjE6MzkrMDA6MDCEmdLCAAAAAElFTkSuQmCC
*/