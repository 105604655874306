/* General global Style */

/* ======== Google Font ======== */

/* quicksand-regular - latin */
@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/quicksand-v24-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/quicksand-v24-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/quicksand-v24-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/quicksand-v24-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/quicksand-v24-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/quicksand-v24-latin-regular.svg#Quicksand') format('svg'); /* Legacy iOS */
}
/* quicksand-500 - latin */
@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/quicksand-v24-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/quicksand-v24-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/quicksand-v24-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/quicksand-v24-latin-500.woff') format('woff'), /* Modern Browsers */
    url('../fonts/quicksand-v24-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/quicksand-v24-latin-500.svg#Quicksand') format('svg'); /* Legacy iOS */
}
/* quicksand-600 - latin */
@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/quicksand-v24-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/quicksand-v24-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/quicksand-v24-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/quicksand-v24-latin-600.woff') format('woff'), /* Modern Browsers */
    url('../fonts/quicksand-v24-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/quicksand-v24-latin-600.svg#Quicksand') format('svg'); /* Legacy iOS */
}
/* quicksand-700 - latin */
@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/quicksand-v24-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/quicksand-v24-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/quicksand-v24-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/quicksand-v24-latin-700.woff') format('woff'), /* Modern Browsers */
    url('../fonts/quicksand-v24-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/quicksand-v24-latin-700.svg#Quicksand') format('svg'); /* Legacy iOS */
}


/* ======== Universal ======== */
/* replace main color #62a594 by #62a594 */
/* replace hover color #2D7161 by #2D7161 */
/* color palette https://mycolor.space/?hex=%2362A594&sub=1 */

html, #root {
    height: 100%;
}

body {
    height: 100%;
    overflow: hidden!important;
    margin-bottom: 72px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    color: #62a594;
}

.-hidden {
    display:none;
    opacity:0;
}

.modal-icon {
    margin-bottom: 10px;
}

.modal-headline {
    font-weight: bolder;
}

.container {
    margin-bottom: 72px!important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 4em);
}

a {
    color: #62a594;
}

a:hover, a:active {
   color: #2D7161;
}

h1 {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 28px;
}

h4 {
    font-weight: bold;
}

h5 {
    font-size: 14px;
    font-weight: bold;
}

table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
}

th, td {
    width: 200px !important;
    max-width: 200px !important;
    word-wrap: break-word;
}

.subtitle > h4,
.subtitle h5 {
    text-align: center;
}

.column > h2 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px 0 5px 15px;
    margin: 0 -15px 20px -15px;
    background-color: #eee;
}

.showdown-container {
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.modal-title {
    text-align:center;
}

.landscape-banner {
    border: 1px solid;
    padding:20px;
    display:none;
}

@media screen and (max-height:560px) { 
    .showdown-cards {
        display: none !important;
    }
    .landscape-banner {
        display: block;
    }
}

/* ======== Buttons ======== */

.listBtn {
    position: fixed;
    bottom: 0;
    color: gray;
    font-size: 100%;
    text-decoration: none;
    margin: 0;
    left: 10px;
    float:left;
    padding: 14px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid darkgray;
    margin-bottom:10px;
    font-family: "Arial";
    background-color: white;
}

.listBtn:hover {
    background-color: #c3c3c3;
}


input[type=checkbox], input[type=radio] {
    margin: 5px;
}

.btn-primary, .btn-primary:active, .btn-primary:focus, .btn-primary:focus:active {
    background-color: #62a594!important;
    border-color: #62a594!important;
    box-shadow: none!important;
}

.btn-primary:hover:not(.undo-btn,.settings-btn,.pin-list,.pf-main) {
    background-color: #2D7161!important;
    border-color: #2D7161!important;
    box-shadow: none!important;
}

.btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary:focus, .btn-outline-secondary:focus:active {
    background-color: #ffffff!important;
    border-color: #62a594!important;
    color: #62a594!important;
    box-shadow: none!important;
}

.btn-outline-secondary:hover:not(.undo-btn,.settings-btn,.pin-list,.pf-main) {
    background-color: #ffffff!important;
    border-color: #2D7161!important;
    color: #2D7161!important;
    box-shadow: none!important;
}

.btn {
    border-radius: 1.5rem!important;
    font-weight: 500;
}

.close {
    font-size: 2em!important;
    font-weight: normal!important;
    color: #fff!important;
    opacity: 1!important;
    top: 15px!important;
}

/* ======== Logo ======== */

.header-logo-container {
    z-index: 1!important;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 1200px) {
    .header-logo-container {
        max-width: 60vw;
        margin: auto;
    }

    .header-logo {
        max-width: 100%;
    }

    .container {
        height:calc(100% - 30vh)!important;
    }
}

@media (max-width: 1200px) {
    .header-logo-container {
       display:none
    }
}

.modal-logo-container {
    max-width: 100%;
    width: 100%;
}

.modal-logo {
    max-width: 100%;
}

/* ======== Responsive ======== */

@media (max-width: 768px) {
    .card {
        min-width: 100%!important;
        max-width: 100%!important;
    }
}

@media (min-width: 450px) {
    .card-row {
        width:100%;
        margin:auto!important;
    }
    .card {
        margin: 10px 0 !important;
    }
}

@media (max-width: 450px) {
    .card-row {
        width:100%;
        margin:auto!important;
    }
}

@media (max-width: 486px) {
    .modal {
        padding: 0 !important;
    }

    .modal .modal-dialog {
        width: 100%;
        max-width: none;
        height: 100%;
        margin: 0;
    }

    .modal .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }

    .modal .modal-body {
       /*overflow-y: auto;*/
    }
}

@media (min-width: 450px) {
    .modal-content {
        height: 85vh;
    }
}

@media (min-width: 1600px) {
    main .centered-content {
        margin: auto;
    }
}

/* ======== Modal Dialogs ======== */

#canvas_confetti {
    width: 100%!important;
    z-index:9999!important;
    /*height: 100%!important;*/
}

.modal-content {
    background-color: #62a594!important;
    /*border-radius: 1rem 1rem .3rem .3rem!important;*/
    border: none!important;
    /*text-align:center;*/
}

.modal-body {
    background-color: #ffffff!important;
    border-radius:1rem 1rem .3rem .3rem!important;
    width:100%;
    font-size: 1.2rem;
    font-weight: 500;
    padding-top: 2em;
}

.modal-header, .modal-footer {
    border:0!important;
    color: #ffffff;
}

.modal-title {
    margin:auto;
    width:100%;
}

.modal-footer {
    padding: 0 !important;
}

.modal-header {
    min-height: 25%;
    z-index: 19999;
}

/* ======== Main ======== */

main {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 60px;
}

main .main-headline {
    font-size: 20px;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px 0 5px 0;
    margin: 0 -15px 20px -15px;
    background-color: #f2f2f2;
}

main .headline {
    font-size: 25px;
    text-align: center;
    padding: 22px 0 5px 15px;
    margin: 0 -15px 20px -15px;
}

main .centered-content {
    margin: 0 77px 0 77px;
    max-width: 1400px;
}

/* ======== Misc ======== */

.fav {
    border: 3px solid #62a594;
}

.pinned-items-row {
    height: 15em;
}

.pinned-items-row:not(:last-child) {
    border-bottom: 1px solid #62a594;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.welcome-msg {
    margin:auto;
    text-align: center;
    padding: 1rem;
    position:relative;
    height:100%;
}

.pin-modal, .settings-modal {
    overflow-y: auto;
}

.hidden {
    display: none !important;
}

.showdown-cards {
    max-width: 100%;
}

.result-btn {
    margin: 1em;
}