/* Card Style */

/* ======== Animations ======== */

.flyin-left {
    animation: fly-left 1.2s;
}

@keyframes fly-left {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0%);
    }
}

.flyin-left-out {
    animation: fly-left-out 1.2s;
}

@keyframes fly-left-out {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.flyin-right {
    animation: fly-right 1.2s;

}

@keyframes fly-right {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0%);
    }
}

.flyin-right-out {
    animation: fly-right-out 1.2s;
}

@keyframes fly-right-out {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(100%);
    }
}
@keyframes flip-card {
    100%{
        -webkit-transform: scale(1.05) rotateY(180deg);
        -moz-transform: scale(1.05) rotateY(180deg);
        -o-transform: scale(1.05) rotateY(180deg);
        transform: scale(1.05) rotateY(180deg);
    }
    to {
        -webkit-transform: scale(1.0) rotateY(360deg);
        -moz-transform: scale(1.0) rotateY(360deg);
        -o-transform: scale(1.0) rotateY(360deg);
        transform: scale(1.0) rotateY(360deg);
    }
}

.zoom {
    transition: transform .2s;
}

.flip {
    animation: flip-card 0.5s;
}

/* ======== Card itself ======== */

.card-row {
    perspective: 1000px!important;
}

.hidden-card {
    opacity: 0!important;
}

.card-menu {
    flex-wrap:nowrap!important;
    padding:10px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.card {
    margin: 25px;
    text-align: center;
    border-radius: 1rem !important;
    padding: 15px;
    min-width: 500px;
    max-width: 500px;
    position: relative;
    transition: transform 1s;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
    width: 100%;

}

.card-front {
    backface-visibility: hidden;
    transform: rotateX(0deg) rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    height: 100%;
    width: 100%;
}

.card-back {
    border-radius: 1rem !important;
    transform: rotateX( 0deg ) rotateY( 180deg );
    background: #62a594;
    width: 100% !important;
    height: 100% !important;
    color:white!important;
    z-index: -1!important;
    position:absolute;
    top:0;
    right:0;
    alignment: center!important;
    justify-content: center!important;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
}

.card-back--back img {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.card-body {
    padding:0!important;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.card-img {
    max-width:100%!important;
    width: auto!important;
    position:relative;
}

.card-footer {
    background-color: transparent!important;
    border-top: 0!important;
    flex-wrap: nowrap!important;
    padding: 5px!important;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.card-footer-btn {
    /* font-weight: bold; */
}

.image-visible {
    opacity: 1;
}
.image-hidden {
    opacity: 0;
}

.spinner-column {
    z-index: 1000!important;
    position: absolute;
    top:30%;
    left:45%;
}

.card-title {
    width: 90%;
    max-width: 90%;
    margin-left:auto;
    margin-right:auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}


/* ======== Responsive ======== */

/*@media only screen and (min-width: 1000px) {
    .zoom:hover {
        -ms-transform: scale(1.05);
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
    }
}

@media (min-width: 768px) {
    .container {
        max-width: min-content !important;
    }
}*/

@media (max-width: 450px) {
    .img-column {
        height: 40%;
        justify-content: center;
        margin:auto;
    }

    .card {
        margin: 7px!important;
        min-width: inherit !important;
        max-width: inherit !important;
    }
}

@media (min-width: 450px) {
    .img-column {
        height: 50%;
        justify-content: center;
        margin:auto;
    }
}

@media only screen and (max-width: 420px) {
    .embed-responsive-item {
        height: 15vh!important;
    }
}

@media only screen and (min-width: 421px) and (max-width: 450px) {
    .embed-responsive-item {
        height: 20vh!important;
    }
}

@media only screen and (min-width: 451px) {
    .embed-responsive-item {
        height: 20vh!important; /* 40 */
    }
    .card {
        height: 41vh !important;
    }
}


@media only screen and (max-width: 351px),
            screen and (max-height: 680px) {
    .card {
        font-size: .9rem;
    }

    .card-menu {
        padding:0!important;
    }

    .card-footer-btn {
        padding: 5px!important;
        font-size: .9rem;
    }

    .card-footer-btn:nth-child(1) {
        margin-right: 7px !important;
    }

    .card-footer-icon {
        margin-right:2px!important;
    }
}

@media only screen and (min-width: 321px) and (max-width: 450px) {
    .card-footer-btn:nth-child(1) {
        margin-right: 7px !important;
    }

    .card-footer-icon {
        margin-right:5px!important;
    }
}

@media only screen and (min-width: 451px) {
    .card-footer-btn:nth-child(1) {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }

    .card-footer-icon {
        margin-right:15px!important;
    }
}

@media only screen and (min-width: 768px) {
    .card {
        width:70%!important;
    }

    .card-row {
        justify-content: center !important;
    }

}

@media only screen and (min-width: 1200px) {
    .showdown-cards {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

/*
@media only screen and (max-height: 736px) {
    .card-footer-btn {
    //font-size: 0.7em!important;
    }
    .card-title {
    //font-size:14px!important;
    }
    .card {
        max-height: 18em!important;
    }
    .card-img {
        max-height: 100px!important;
    }
}
*/

@media only screen and (min-width: 1200px) {
    .card {
        margin: 20px !important;
        /*height:70vh !important;
        padding-top:10% !important;*/
    }
    .card-row {
        justify-content: inherit !important;
    }
}

@supports (-webkit-touch-callout: none) {
    /*.card .card-footer .card-footer-btn {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .card {
        border:none;
        box-shadow: none;
        min-width: 500px;
        max-width: 500px;
        margin:25px;
    }

    .card-front {
        width: 100%;
        min-height: 100%;
        padding: 15px;
        -webkit-backface-visibility: hidden;
        -webkit-transform-style: preserve-3d;
        -webkit-transition: all .5s ease-in-out;
        -webkit-transform: rotateY(0deg);
        border: 1px solid rgba(0,0,0,.125);
    }

    .card-back {
        border: white 15px solid;
        -webkit-backface-visibility: hidden;
        -webkit-transform-style: preserve-3d;
        -webkit-transition: all .5s ease-in-out;
        -webkit-transform: rotateY(180deg);
    }

    .card-footer-btn {
        backface-visibility: visible!important;
    }

    .flip .card {
        position: absolute;
        min-height: 460px;
        width: 90vw;
        -webkit-perspective: 1000px;
    }

    .flip .card-front {
        border: none;
        padding: 15px;
        -webkit-transform: rotateY(-1800deg);
        opacity: 0 !important;
    }

    .flip-back .card-front {
        padding: 15px;
        border: 1px solid rgba(0,0,0,.125);
        -webkit-transform: rotateY(0deg);
        opacity: 1 !important;
    }

    .flip-back .card-back {
        -webkit-transform: rotateY(180deg);
    }

    .flip .card-back {
        -webkit-transform: rotateY(0deg);
    }

    .flip {
        box-shadow: none !important;
        border: none !important;
    }*/

    @keyframes flip-card {
        100% {
           transform: none !important;
        }

        to {
            transform: none !important;
        }
    }
}

.embed-responsive-item {
    object-fit: scale-down;
}