/* Navigation Style */

/* ======== Basic ======== */

#basic-navbar-nav {
    text-align:center;
}

.navbar {
    background-color: #62a594;
    padding: 0!important;
    z-index: 10 !important;
}

.pin-list, .pf-main, /* .undo-btn, */ .settings-btn {
    /*padding: 12px 25px 12px 25px!important;*/
    padding: .8em 1.3em .8em 1.3em !important;
    border:0!important;
    color:white!important;
}

.scale-up-center {
    -webkit-animation: scale-up-center 0.9s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-center 0.9s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}


.pin-list:hover, .pf-main:hover, .undo-btn:hover, .settings-btn:hover {
    color:#F6FDFB!important;
}

.badge {
    top: -20px !important;
    background-color: #2D7161 !important;
}

.nav-description {
    color: white;
    font-size: 1rem;
    padding: 0;
    text-align: center;
    margin-bottom: .3em;
}

.nav-icon-bi {
    padding: 3px!important;
    /*padding-top: 7px!important;*/
}

.button-container {

}
/* ======== Animations ======== */

.loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #2D7161;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.scale-up-center {
    -webkit-animation: scale-up-center 0.9s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-center 0.9s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/*#open-modal-icon:hover {
    animation: pop 0.9s;
}

#undo-icon:hover {
    animation: pop 0.9s;
}

#pin-list-icon:hover {
    animation: pop 0.9s;
}*/

@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes pop{
    50%  {transform: scale(0.8);}
}

